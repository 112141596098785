const classNames = require('classnames');
const PropTypes = require('prop-types');
const React = require('react');

import styles from './flex-row.css';

const FlexRow = props => (
    <props.as className={classNames(styles.flexRow, props.className)}>
        {props.children}
    </props.as>
);

FlexRow.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

FlexRow.defaultProps = {
    as: 'div'
};

export default FlexRow;