exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".button_button_1z6YG {\n    display: inline-block;\n    margin: 0.5em 0;\n    border: 0;\n    border-radius: 0.5rem;\n    background-color: #4d97ff;\n    cursor: pointer;\n    padding: 1em 1.25em;\n    color: white;\n    font-size: 0.8rem;\n    font-weight: bold;\n    /* USER BUTTON STATES */\n  }\n  .button_button_1z6YG:focus {\n    outline: none;\n    /* DATA BUTTON STATES */\n  }\n  .button_button_1z6YG.button_white_9iHih {\n    background-color: white;\n    color: #4d97ff;\n  }\n  .button_button_1z6YG.button_pass_1bPLR {\n    background-color: #0fbd8c;\n  }\n  .button_button_1z6YG.button_fail_EYjUG {\n    background-color: #ffab1a;\n  }\n  .button_button_1z6YG:disabled {\n    -webkit-box-shadow: none;\n            box-shadow: none;\n  }\n  .button_button_1z6YG.button_large_1qxDV {\n    border-radius: 0.25rem;\n    font-size: 1rem;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    padding: 0.75rem 1.25rem 0.8125rem;\n  }\n  .button_button_1z6YG.button_icon-right_3WpYp img {\n    height: 1.25rem;\n    margin-bottom: -0.25rem;\n    margin-left: 0.5rem;\n    margin-right: -0.25rem;\n  }\n  ", ""]);

// exports
exports.locals = {
	"button": "button_button_1z6YG",
	"white": "button_white_9iHih",
	"pass": "button_pass_1bPLR",
	"fail": "button_fail_EYjUG",
	"large": "button_large_1qxDV",
	"icon-right": "button_icon-right_3WpYp",
	"iconRight": "button_icon-right_3WpYp"
};