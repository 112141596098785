exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".input_row_36tcQ label {\n    font-weight: 500;\n  }\n  .input_input_2rFk- {\n    -webkit-transition: all 0.5s ease;\n    -o-transition: all 0.5s ease;\n    transition: all 0.5s ease;\n    margin-bottom: 0.75rem;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    border-radius: 5px;\n    background-color: #fafafa;\n    padding: 0 1rem;\n    height: 3rem;\n    color: #575e75;\n    font-size: 0.875rem;\n  }\n  .input_input_2rFk-:focus {\n    -webkit-transition: all 0.5s ease;\n    -o-transition: all 0.5s ease;\n    transition: all 0.5s ease;\n    outline: none;\n    border: 1px solid #4d97ff;\n  }\n  .input_input_2rFk-.input_fail_1gRbi {\n    border: 1px solid #ffab1a;\n  }\n  .input_input_2rFk-.input_pass_2HZyD {\n    border: 1px solid #0fbd8c;\n    /* IE10/11-specific style resets */\n  }\n  .input_input_2rFk-::-ms-reveal, .input_input_2rFk-::-ms-clear {\n    display: none;\n  }\n", ""]);

// exports
exports.locals = {
	"row": "input_row_36tcQ",
	"input": "input_input_2rFk-",
	"fail": "input_fail_1gRbi",
	"pass": "input_pass_2HZyD"
};