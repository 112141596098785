exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".login_login_28t4b {\n    padding: 10px;\n    width: 200px;\n    line-height: 1.5rem;\n    white-space: normal;\n    color: white;\n    font-size: 0.8125rem;\n  }\n  .login_button_2wNnH {\n    padding: 0.75em;\n  }\n  .login_row_3Bxg8 {\n    margin-bottom: 1.25rem;\n  }\n  .login_input_3W2ae {\n    margin-bottom: 12px;\n    width: calc(100% - 30px);\n    height: 2.25rem;\n  }\n  .login_login-label_nXzl8 {\n    padding-top: 5px;\n    font-weight: bold;\n  }\n  .login_spinner_2WfLE {\n    margin: 0 0.8rem;\n    width: 0.5rem;\n    height: 0.5rem;\n    vertical-align: middle;\n    border-color: #4d97ff;\n  }\n  .login_spinner_2WfLE:after {\n    border-top-color: #4d97ff !important;\n    top: -0.6rem;\n    left: -0.6rem;\n\n  }\n  .login_submit-row_TvvaP {\n    -webkit-box-pack: justify;\n    -webkit-justify-content: space-between;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n\n  }\n  .login_submit-button_1iakZ {\n    margin-top: 5px;\n    display: inline-block;\n    border: 0;\n    border-radius: 0.5rem;\n    background-color: #fff;\n    cursor: pointer;\n    padding: 1.25em 0.5em;\n    color: #4d97ff;\n    font-size: 0.8rem;\n    font-weight: bold;\n  }\n  .login_login_28t4b a {\n    margin: auto 0;\n    color: white;\n  }\n  .login_login_28t4b a:link, .login_login_28t4b a:visited, .login_login_28t4b a:active {\n    color: white;\n  }\n  .login_login_28t4b a:hover {\n    background-color: transparent;\n  }\n  .login_login_28t4b .login_error_3uwFW {\n    border: 1px solid rgba(0, 0, 0, 0.2);\n    border-radius: 5px;\n    background-color: #ffab1a;\n    padding: 0.75em 1em;\n  }\n  ", ""]);

// exports
exports.locals = {
	"login": "login_login_28t4b",
	"button": "login_button_2wNnH",
	"row": "login_row_3Bxg8",
	"input": "login_input_3W2ae",
	"login-label": "login_login-label_nXzl8",
	"loginLabel": "login_login-label_nXzl8",
	"spinner": "login_spinner_2WfLE",
	"submit-row": "login_submit-row_TvvaP",
	"submitRow": "login_submit-row_TvvaP",
	"submit-button": "login_submit-button_1iakZ",
	"submitButton": "login_submit-button_1iakZ",
	"error": "login_error_3uwFW"
};