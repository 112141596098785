exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".menu-mode_mode_T2Zp_ {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    background : hsla(0, 100%, 100%, 0.25);\n    border-radius: 4px;\n    padding: 3px 5px;\n    margin-right: 8px;\n}\n.menu-mode_mode-status_RN2RU {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    border-radius: 4px;\n    background :#E9EEF2;\n    padding: 5px;\n}\n.menu-mode_mode_T2Zp_>div:first-child {\n    line-height: 27px;\n    padding-right: 8px;\n    padding-left: 8px;\n}\n.menu-mode_mode_T2Zp_>div:last-child {\n    padding: 0;\n    height: 27px;\n    line-height: 27px;\n}\n.menu-mode_mode_T2Zp_ .menu-mode_stage_fnlkH, .menu-mode_mode_T2Zp_ .menu-mode_robot_3FK23 {\n    cursor: pointer;\n    color: hsla(225, 15%, 40%, 0.75);\n    padding: 0 10px;\n}\n.menu-mode_mode_T2Zp_ .menu-mode_robot_3FK23 {\n    border-top-left-radius: 4px;\n    border-bottom-left-radius: 4px;\n}\n.menu-mode_mode_T2Zp_ .menu-mode_stage_fnlkH {\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px;\n}\n.menu-mode_mode_T2Zp_ .menu-mode_active_2srYG {\n    background-color: #fff;\n    color:  hsla(215, 100%, 65%, 1)\n}", ""]);

// exports
exports.locals = {
	"mode": "menu-mode_mode_T2Zp_",
	"mode-status": "menu-mode_mode-status_RN2RU",
	"modeStatus": "menu-mode_mode-status_RN2RU",
	"stage": "menu-mode_stage_fnlkH",
	"robot": "menu-mode_robot_3FK23",
	"active": "menu-mode_active_2srYG"
};