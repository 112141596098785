/*
NOTE: this file only temporarily resides in scratch-gui.
Nearly identical code appears in scratch-www, and the two should
eventually be consolidated.
*/

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {defineMessages, intlShape} from 'react-intl';
import styles from './menu-mode.css';

const ModeMessages = defineMessages({
    mode: {
        id: 'gui.menuMode.mode',
        defaultMessage: 'Mode',
        description: 'Mode label'
    },
    stage: {
        id: 'gui.menuMode.stage',
        defaultMessage: 'Stage',
        description: 'Mode stage'
    },
    robot: {
        id: 'gui.menuMode.robot',
        defaultMessage: 'Robot',
        description: 'Mode robot'
    }
});
const MenuMode = ({
    intl,
    className,
    mode,
    handleSwitchMode
}) => {

    const [status, setStatus] = useState(mode);

    const changeMode = mode => {
        setStatus(mode)
    }
    useEffect(() => {
        return () => {
            console.log('xxx')
        };
    }, [mode])

    const switchMode = mode => {
        handleSwitchMode(mode)
    }

    return (

        <React.Fragment>
            <div className={classNames(styles.mode, className)}>   
                <div>
                    <label>{intl.formatMessage(ModeMessages.mode)}</label>
                </div>
                {status === 'stage' ? (
                    <div className={classNames(styles.modeStatus)}>
                        <div 
                            onClick={()=> switchMode('robot')} 
                            className={classNames(styles.robot)}
                            >
                            {intl.formatMessage(ModeMessages.robot)}
                        </div>
                        <div 
                            onClick={()=> switchMode('stage')} 
                            className={classNames(styles.stage, styles.active)}
                            >
                            {intl.formatMessage(ModeMessages.stage)}
                        </div>
                    </div>
                    ): (
                    <div className={classNames(styles.modeStatus)}>
                        <div 
                            onClick={()=> switchMode('robot')} 
                            className={classNames(styles.robot, styles.active)}
                            >
                            {intl.formatMessage(ModeMessages.robot)}
                        </div>
                        <div 
                            onClick={()=> switchMode('stage')} 
                            className={classNames(styles.stage)}
                            >
                            {intl.formatMessage(ModeMessages.stage)}
                        </div>
                    </div>
                    )}
            </div>
        </React.Fragment>
    )
    
};

MenuMode.propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    mode: PropTypes.string.isRequired,
    handleSwitchMode: PropTypes.func.isRequired
};

export default MenuMode;
