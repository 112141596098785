import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import bindAll from 'lodash.bindall';
import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';
import styles from './board-modal.css';
import unoIcon from './icon--uno.png';
import megaIcon from './icon--mega.png';
import nanoIcon from './icon--nano.png';

const messages = defineMessages({
    boardModalTitle: {
        defaultMessage: 'Select a board',
        description: 'Select a board',
        id: 'gui.boardModal.boardModalTitle'
    },
    arduinoUno: {
        defaultMessage: 'Arduino Uno',
        description: 'Menu bar item for Arduino Uno',
        id: 'gui.menuBar.arduinoUno'
    },
    arduinoMega: {
        defaultMessage: "Arduino Mega",
        description: "Menu bar item for Arduino Mega",
        id: "gui.menuBar.arduinoMega"
    },
    arduinoNano: {
        defaultMessage: "Arduino Nano",
        description: "Menu bar item for Arduino Nano",
        id: "gui.menuBar.arduinoNano"
    },
});
class BoardModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'onSelectBoard'
        ])
    }
    
    onSelectBoard(board) {
        this.props.onSelectBoard(board);
    }
   
    render() {
        const {intl, ...props} = this.props;
        return (
            <Modal
                id="gui.boardModal"
                className={styles.modalContent}
                contentLabel={intl.formatMessage(messages.boardModalTitle)}
                onRequestClose={props.onClose}
            >
                <Box className={styles.body}>
                    <div className={styles.box}>
                        <div 
                            className={styles.board} 
                            onClick={() => this.onSelectBoard('arduinoUno')}
                        >
                            <p>{intl.formatMessage(messages.arduinoUno)}</p>
                            <img src={unoIcon} />
                        </div>
                        <div 
                            className={styles.board} 
                            onClick={() => this.onSelectBoard('arduinoMega')}
                        >
                            <p>{intl.formatMessage(messages.arduinoMega)}</p>
                            <img src={megaIcon} />
                        </div>
                        <div 
                            className={styles.board}
                            onClick={() => this.onSelectBoard('arduinoNano')}
                        >
                            <p>{intl.formatMessage(messages.arduinoNano)}</p>
                            <img src={nanoIcon} />
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }
}
// const BoardModal = ({intl, ...props}) => (
    
// );

BoardModal.propTypes = {
    intl: intlShape.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelectBoard: PropTypes.func.isRequired,
};

export default injectIntl(BoardModal);
