const classNames = require('classnames');
const FRCInput = require('formsy-react-components').Input;
const omit = require('lodash.omit');
const PropTypes = require('prop-types');
const React = require('react');

const defaultValidationHOC = require('../forms/validations.jsx').defaultValidationHOC;
import inputHOC from '../forms/input-hoc.jsx';

import styles from './input.css';
import rowClassName from './row.css';

const Input = ({
    className,
    label,
    ...props
}) => (
    <FRCInput
        className={classNames(styles.input)}
        label={label}
        rowClassName={classNames(
            className,
            rowClassName.noLabel
            // {'noLabel': (typeof label === 'undefined')}
        )}
        {...omit(props, ['className'])}
    />
);

Input.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string
};

export default inputHOC(defaultValidationHOC(Input));
