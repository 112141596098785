exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".flex-row_flex-row_1lBzJ {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    -webkit-flex-wrap: wrap;\n        -ms-flex-wrap: wrap;\n            flex-wrap: wrap;\n    -webkit-justify-content: space-around;\n        -ms-flex-pack: distribute;\n            justify-content: space-around;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n  }\n  .flex-row_flex-row_1lBzJ.flex-row_column_2ZRYK {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n  }\n  .flex-row_flex-row_1lBzJ.flex-row_uneven_8E6LC {\n    -webkit-box-align: start;\n    -webkit-align-items: flex-start;\n        -ms-flex-align: start;\n            align-items: flex-start;\n  }\n  .flex-row_flex-row_1lBzJ.flex-row_uneven_8E6LC .flex-row_short_17dWK {\n    width: 0.1432291667em;\n  }\n  .flex-row_flex-row_1lBzJ.flex-row_uneven_8E6LC .flex-row_long_2SAQs {\n    width: 0.4036458333em;\n    text-align: left;\n  }\n  @media only screen and (max-width: 767px) {\n    .flex-row_flex-row_1lBzJ {\n      -webkit-box-orient: vertical;\n      -webkit-box-direction: normal;\n      -webkit-flex-direction: column;\n          -ms-flex-direction: column;\n              flex-direction: column;\n    }\n    .flex-row_flex-row_1lBzJ.flex-row_uneven_8E6LC .flex-row_short_17dWK, .flex-row_flex-row_1lBzJ.flex-row_uneven_8E6LC .flex-row_long_2SAQs {\n      margin: auto;\n      width: 90%;\n    }\n  }\n  ", ""]);

// exports
exports.locals = {
	"flex-row": "flex-row_flex-row_1lBzJ",
	"flexRow": "flex-row_flex-row_1lBzJ",
	"column": "flex-row_column_2ZRYK",
	"uneven": "flex-row_uneven_8E6LC",
	"short": "flex-row_short_17dWK",
	"long": "flex-row_long_2SAQs"
};