const bindAll = require('lodash.bindall');
const FormattedMessage = require('react-intl').FormattedMessage;
const PropTypes = require('prop-types');
const React = require('react');

import classNames from 'classnames';
import Form from '../forms/form.jsx';
import Input from './input.jsx';
import Button from '../forms/button.jsx';
import Spinner from '../spinner/spinner.jsx';
import FlexRow from '../flex-row/flex-row.jsx';

import styles from './login.css';

class Login extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSubmit'
        ]);
        this.state = {
            waiting: false
        };
    }
    handleSubmit (formData) {
        this.setState({waiting: true});
        this.props.onLogIn(formData, () => {
            this.setState({waiting: false});
        });
    }
    render () {
        let error;
        if (this.props.error) {
            error = <div className={classNames(styles.error)}>{this.props.error}</div>;
        }
        return (
            <div className={classNames(styles.login)}>
                <Form onSubmit={this.handleSubmit}>
                    <label 
                        className={classNames(styles.loginLabel)}
                        htmlFor="username"
                        key="usernameLabel"
                    >
                        <FormattedMessage id="general.username" />
                    </label>
                    <Input
                        required
                        key="usernameInput"
                        maxLength="30"
                        name="username"
                        type="text"
                    />
                    <label
                        className={classNames(styles.loginLabel)}
                        htmlFor="password"
                        key="passwordLabel"
                    >
                        <FormattedMessage id="general.password" />
                    </label>
                    <Input
                        required
                        key="passwordInput"
                        name="password"
                        type="password"
                    />
                    <FlexRow className={classNames(styles.submitRow)}>
                        {this.state.waiting ? [
                            <Button
                                className={classNames(styles.submitButton, styles.white)}
                                disabled="disabled"
                                key="submitButton"
                                type="submit"
                            >
                                <Spinner
                                    className={classNames(styles.spinner)}
                                    color="blue"
                                />
                            </Button>
                        ] : [
                            <Button
                                className={classNames(styles.submitButton, styles.white)}
                                key="submitButton"
                                type="submit"
                            >
                                <FormattedMessage id="general.signIn" />
                            </Button>
                        ]}
                        <a
                            href="#"
                            key="passwordResetLink"
                        >
                            <FormattedMessage id="login.needHelp" />
                        </a>
                    </FlexRow>
                    {error}
                </Form>
            </div>
        );
    }
}

Login.propTypes = {
    error: PropTypes.string,
    onLogIn: PropTypes.func
};

export default Login;
